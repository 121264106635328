<template>
  <div class="error-404">
      <h2 class="">Error Page</h2>
    
  </div>
</template>
<script>

export default {
}
</script>

